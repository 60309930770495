import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

import {
  Newspaper,
  Download,
  ExternalLink,
  Mail,
  Phone,
  ArrowRight,
  Award,
  TrendingUp,
  Users
} from 'lucide-react';

interface PressRelease {
  id: string;
  date: string;
  title: string;
  excerpt: string;
  link: string;
}

interface NewsArticle {
  id: string;
  date: string;
  title: string;
  source: string;
  logo: string;
  link: string;
}

interface Award {
  id: string;
  title: string;
  organization: string;
  date: string;
  description: string;
}

const pressReleases: PressRelease[] = [
  {
    id: '1',
    date: 'March 15, 2024',
    title: 'Newsroom Introduces AI-Powered Widget Generation',
    excerpt: 'Leading trading technology platform launches revolutionary AI features for custom widget creation.',
    link: '/press/ai-widget-launch'
  },
  {
    id: '2',
    date: 'February 1, 2024',
    title: 'Newsroom Launches Beta Program',
    excerpt: 'Newsroom announces beta program for new widget generation engine.',
    link: '/press/series-a'
  }
];

const news: NewsArticle[] = [
  {
    id: '1',
    date: 'March 16, 2024',
    title: 'How Newsroom is Revolutionizing Trading Technology',
    source: 'TechCrunch',
    logo: '/media/techcrunch.png',
    link: 'https://techcrunch.com'
  },
  {
    id: '2',
    date: 'March 10, 2024',
    title: 'The Future of Trading: AI-Powered Widgets',
    source: 'Forbes',
    logo: '/media/forbes.png',
    link: 'https://forbes.com'
  }
];

const awards: Award[] = [
  {
    id: '1',
    title: 'Best Trading Technology Innovation',
    organization: 'FinTech Awards 2024',
    date: 'March 2024',
    description: 'Recognized for revolutionary AI-powered widget generation technology.'
  },
  {
    id: '2',
    title: 'Most Promising FinTech Startup',
    organization: 'TechInnovators 2024',
    date: 'February 2024',
    description: 'Selected as one of the top 10 most promising fintech startups.'
  }
];

const Press = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
     

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <div className="text-center">
            <Newspaper className={`w-12 h-12 mx-auto mb-6 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <h1 className={`text-4xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Press Room
            </h1>
            <p className={`text-xl max-w-3xl mx-auto mb-8 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Get the latest news, press releases, and media resources about Newsroom.
            </p>
            <div className="flex justify-center space-x-4">
              <a
                href="/press-kit.zip"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                <Download className="w-5 h-5 mr-2" />
                Download Press Kit
              </a>
              <a
                href="mailto:press@news-room.ca"
                className={`inline-flex items-center px-6 py-3 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-800 text-white hover:bg-gray-700'
                    : 'bg-white text-gray-900 hover:bg-gray-50'
                } transition-colors`}
              >
                <Mail className="w-5 h-5 mr-2" />
                Contact Press Team
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <TrendingUp className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-4xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                2M+
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Widgets Generated
              </div>
            </div>
            <div className="text-center">
              <Users className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-4xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                50K+
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Active Users
              </div>
            </div>
            <div className="text-center">
              <Award className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-4xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                10+
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Industry Awards
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Press Releases */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className={`text-2xl font-bold mb-8 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Latest Press Releases
        </h2>
        <div className="space-y-6">
          {pressReleases.map((release) => (
            <div
              key={release.id}
              className={`p-6 rounded-lg border ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700' 
                  : 'bg-white border-gray-200'
              }`}
            >
              <div className={`text-sm mb-2 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                {release.date}
              </div>
              <h3 className={`text-xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {release.title}
              </h3>
              <p className={`mb-4 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                {release.excerpt}
              </p>
              <a
                href={release.link}
                className="inline-flex items-center text-blue-500 hover:text-blue-600"
              >
                Read More
                <ArrowRight className="w-4 h-4 ml-1" />
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* News Coverage */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            News Coverage
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            {news.map((article) => (
              <a
                key={article.id}
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`p-6 rounded-lg border group transition-colors ${
                  isDarkMode 
                    ? 'bg-gray-900 border-gray-700 hover:border-blue-500' 
                    : 'bg-gray-50 border-gray-200 hover:border-blue-500'
                }`}
              >
                <div className="flex items-start justify-between">
                  <div>
                    <div className={`text-sm mb-2 ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-500'
                    }`}>
                      {article.date}
                    </div>
                    <h3 className={`text-lg font-bold mb-2 group-hover:text-blue-500 transition-colors ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {article.title}
                    </h3>
                    <div className="flex items-center">
                      <span className={`text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        {article.source}
                      </span>
                      <ExternalLink className="w-4 h-4 ml-2" />
                    </div>
                  </div>
                  {article.logo && (
                    <img 
                      src={article.logo} 
                      alt={article.source}
                      className="w-16 h-16 object-contain"
                    />
                  )}
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Awards & Recognition */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className={`text-2xl font-bold mb-8 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Awards & Recognition
        </h2>
        <div className="grid md:grid-cols-2 gap-6">
          {awards.map((award) => (
            <div
              key={award.id}
              className={`p-6 rounded-lg border ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700' 
                  : 'bg-white border-gray-200'
              }`}
            >
              <Award className={`w-8 h-8 mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-sm mb-2 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                {award.date}
              </div>
              <h3 className={`text-xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {award.title}
              </h3>
              <div className={`text-sm font-medium mb-3 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`}>
                {award.organization}
              </div>
              <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                {award.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Press Contact */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className={`p-8 rounded-2xl border text-center ${
            isDarkMode 
              ? 'bg-gray-900 border-gray-700' 
              : 'bg-gray-50 border-gray-200'
          }`}>
            <h2 className={`text-2xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Press Contact
            </h2>
            <p className={`mb-6 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              For press inquiries, please contact our media relations team.
              We typically respond within 24 hours.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
              <a
                href="mailto:press@news-room.ca"
                className="inline-flex items-center justify-center"
              >
                <Mail className="w-5 h-5 mr-2 text-blue-500" />
                <span className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  press@news-room.ca
                </span>
              </a>
              <a
                href="tel:+17787510983"
                className="inline-flex items-center justify-center"
              >
                <Phone className="w-5 h-5 mr-2 text-blue-500" />
                <span className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  +1 (778) 751-0983
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default Press;