// src/contexts/AuthContext.tsx
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { 
  AuthState, 
  User, 
  LoginCredentials, 
  RegisterCredentials,
  SocialAuthCredentials 
} from '../types/auth';
import { authService } from '../services/authService';

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  login: (credentials: LoginCredentials) => Promise<void>;
  register: (credentials: RegisterCredentials) => Promise<void>;
  socialAuth: (credentials: SocialAuthCredentials) => Promise<void>;
  logout: () => void;
  updateProfile: (updates: Partial<User>) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  isLoading: true,
  error: null,
};

type AuthAction =
  | { type: 'AUTH_START' }
  | { type: 'AUTH_SUCCESS'; payload: User }
  | { type: 'AUTH_ERROR'; payload: string }
  | { type: 'AUTH_LOGOUT' }
  | { type: 'PROFILE_UPDATE'; payload: User };

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'AUTH_START':
      return { ...state, isLoading: true, error: null };
    case 'AUTH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
        error: null,
      };
    case 'AUTH_ERROR':
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };
    case 'AUTH_LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        error: null,
      };
    case 'PROFILE_UPDATE':
      return { ...state, user: action.payload };
    default:
      return state;
  }
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const socialAuth = async (credentials: SocialAuthCredentials) => {
    dispatch({ type: 'AUTH_START' });
    try {
      const { user } = await authService.socialAuth(credentials);
      dispatch({ type: 'AUTH_SUCCESS', payload: user });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Social authentication failed';
      dispatch({ type: 'AUTH_ERROR', payload: message });
      throw error;
    }
  };

  const value: AuthContextType = {
    user: state.user,
    isAuthenticated: state.isAuthenticated,
    isLoading: state.isLoading,
    error: state.error,
    login: async (credentials) => {
      dispatch({ type: 'AUTH_START' });
      try {
        const { user } = await authService.login(credentials);
        dispatch({ type: 'AUTH_SUCCESS', payload: user });
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Login failed';
        dispatch({ type: 'AUTH_ERROR', payload: message });
        throw error;
      }
    },
    register: async (credentials) => {
      dispatch({ type: 'AUTH_START' });
      try {
        const { user } = await authService.register(credentials);
        dispatch({ type: 'AUTH_SUCCESS', payload: user });
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Registration failed';
        dispatch({ type: 'AUTH_ERROR', payload: message });
        throw error;
      }
    },
    socialAuth,
    logout: () => {
      authService.logout();
      dispatch({ type: 'AUTH_LOGOUT' });
    },
    updateProfile: async (updates) => {
      try {
        const user = await authService.updateProfile(updates);
        dispatch({ type: 'PROFILE_UPDATE', payload: user });
      } catch (error) {
        throw error;
      }
    },
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};